import { imageURL } from "constants/urls";
import React, { Fragment } from "react";
import "./AdditionalInfo.css";

const AdditionalInfo = ({
  className,
  addlogo,
  path = "projects-gallery",
  isHideLabel = false,
  width = 50,
  height = 50,
  onRemoveHandler
}) => {
  return (
    <div className={`additional-logo ${className ? className : ""}`}>
      {addlogo?.imageName[0] !== undefined && (
        <Fragment>
          {JSON.parse(addlogo?.imageName).map((p, index) => {
            return(
            <div className="relative" key={index}>
              <img
                className="img"
                src={`${imageURL}/${path}/${p}`}
                width={width}
                height={height}
              />
              <div className="absolute right-0 -top-12-px bg-white rounded-full p-2 border-black w-6 h-6 cursor-pointer" onClick={() => onRemoveHandler(p)}>
                <i className="flex items-center -top-1-px fas fa-trash text-red-500 text-xs"></i>
              </div>
            </div>)
          })}
          {isHideLabel !== "true" && <span>{addlogo.addname}</span>}
        </Fragment>
      )}
    </div>
  );
};

export default AdditionalInfo;
