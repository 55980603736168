import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import ConfirmModal from "components/UI/ConfirmModal";
import moment from "moment";
import ReadMore from "components/UI/ReadMore";
import Loading from "components/UI/Loading";
import { Link } from "react-router-dom";

const AirdropList = ({ paginationConfig, onDelete, onEdit, onFetchRecords, airdropList, isLoading }) => {
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onRemoveHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    onEdit(item);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">Id</th>
            <th>Project</th>
            <th>Number Of Steps</th>
            <th>Expected Date</th>
            <th>Category</th>
            <th>Status</th>
            <th>Updated Date</th>
            <th>Updated By</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={10}><Loading></Loading></td></tr> :
            airdropList.map((item) => {
              const project = { imageName: item.imageName, name: item.projectName };
              return (
                <tr key={item.id}>
                  <td>#AD{item.id}</td>
                  <td>
                    <ProjectLogo logo={project} />
                  </td>
                  <td className="max-w-200-px">{item.numberOfSteps}</td>
                  <td>{moment(item.expectedDate).format('DD-MMM-YYYY')}</td>
                  <td>{item.categname}</td>
                  <td>{item.airdrop_status}</td>
                  <td><DateFormat date={item.modifiedAt} /></td>
                  <td>{item.username}</td>
                  <td><Status value={item.active} /></td>
                  <td>
                    <div className="btn-container">
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => onRemoveHandler(item)}
                      >
                        <i className="fas fa-trash text-gray-400"></i>
                      </Button>
                      <Link
                      to={{
                        pathname: "/admin/airdrops/form",
                        data: {
                          ...item, projectId: item.projectId, statusId: item.airdrop_status, numberOfSteps: item.numberOfSteps, categoryId: item.categoryId,
                          videoLink: item.videolink, avgCost: item.avgCost, endDate: item.expectedDate !== "1971-01-01 00:00:00" ? new Date(item.expectedDate) : ""
                        }
                      }}
                    >
                      <Button
                        className="float-right"
                        variant="secondary"
                        // onClick={() => onEditHandler(item)}
                      >
                        <i className="fas fa-edit text-gray-400"></i>
                      </Button>
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default AirdropList;